.demo-box {
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  max-width: 100%;

  .demo-main {
    max-width: 1200px;
    margin: 0 auto;

    > .title {
      text-align: center;
      padding: 25px 0;
      margin-bottom: 15px;

      .text {
        color: $bottle-blue;

        &.big {
          font-size: 22px;
          line-height: 1.364;
          padding-bottom: 20px;
          text-transform: uppercase;
        }

        &.small {
          font-size: 15px;
          line-height: 2;
        }
      }
    }

    h3 {
      font-size: 15px;
      color: $bottle-blue;
      text-transform: uppercase;
      line-height: 2;
    }

    .top {
      .detail {
        ul {
          display: grid;
          grid-template-columns: repeat(auto-fill, 50%);
          margin-bottom: 10px;

          li {
            @include rtl-sass-value(border-radius, 6px 0 0 6px, 0 6px 6px 0);
            background-color: $light-gray;
            height: 70px;
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            font-size: 15px;
            color: $bottle-blue;
            line-height: 2;
            display: -webkit-flex; /* Safari */
            align-items: center; /* Safari */

            b {
              @include rtl-sass-prop(margin-left, margin-right, 30px);
              color: rgb(98, 109, 113);
            }

            &:first-child {
              @include rtl-sass-prop(border-right, border-left, 2px solid $blue);
            }
          }
        }

        span {
          font-size: 15px;
          color: $blue;
          @include rtl-sass-prop(margin-left, margin-right, 30px);
          margin-bottom: 20px;
        }
      }

      .download {
        margin-top: 80px;

        ul {
          padding: 40px 20px;
          border-radius: 8px;
          background-color: $light-gray;
          display: grid;
          grid-template-columns: repeat(auto-fill, calc((100% - 2% * 5) / 6));
          column-gap: 2%;
          row-gap: 20px;

          /deep/ li {
            vertical-align: middle;
            display: inline-block;
            text-align: center;

            .title {
              font-size: 16px;
              color: $primary;
              margin-bottom: 20px;
            }

            .clickable {
              padding-top: 20%;
              border-color: $black;
              border-radius: 10px;
              height: 173px;
              background-color: $white;
              border-style: solid;
              border-width: $border-width;
              border-color: $border-color;
              border-radius: 6px;
              position: relative;

              &:hover {
                background-color: $primary;
                box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;

                .picture {
                  background-position: -75px 0;
                }
              }

              .picture {
                width: 75px;
                height: 75px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 0 0;
                margin: 0 auto;

                &.windows {
                  background-image: url(~@/assets/images/download/windows.png);
                }

                &.mac {
                  background-image: url(~@/assets/images/download/masOS.png);
                }

                &.linux {
                  background-image: url(~@/assets/images/download/linux.png);
                }

                &.iphone {
                  background-image: url(~@/assets/images/download/iphone.png);
                }

                &.android {
                  background-image: url(~@/assets/images/download/android.png);
                }

                &.webtrader {
                  background-image: url(~@/assets/images/download/webTrader.png);
                }
              }

              .download-btn {
                position: absolute;
                bottom: 10px;
                @include rtl-sass-prop(right, left, 10px);
                width: 33px;
                height: 33px;
                background-color: #b8cdea;
                border-radius: 100px;
                background-image: url(~@/assets/images/download/dlBtn.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    margin-top: 80px;
    text-align: center;

    h3 {
      margin-bottom: 0;
    }

    .text {
      text-align: center;
      margin-bottom: 15px;
      padding-bottom: 25px;

      font-size: 15px;
      color: $blue;
      line-height: 1.8;
    }

    .boxs {
      display: grid;
      grid-template-columns: repeat(auto-fit, calc((100% - 2% * 3) / 4));
      column-gap: 2%;
      row-gap: 3%;

      > div {
        border-radius: 8px;
        background-color: $light-gray;
        padding: 20px;
      }

      .title {
        font-size: 15px;
        color: $blue;
        line-height: 1.799;
        @include rtl-sass-value(text-align, left, right);
        margin-bottom: 20px;
      }
    }

    .btn-box {
      padding: 50px 0 80px;
      text-align: center;

      .el-button {
        width: 100%;
        max-width: 235px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .demo-box .demo-main .top .download ul li .clickable {
    height: 126px;

    .picture {
      width: 55px;
      height: 55px;
    }

    &:hover .picture {
      background-position: -55px 0;
    }
  }
}

@media (max-width: 768px) {
  .demo-box .demo-main .bottom h3,
  .demo-box .demo-main > .title .text.big {
    font-size: 22px;
  }

  .demo-box .demo-main .bottom .boxs {
    max-width: 400px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, calc((100% - 2% * 1) / 2));
  }
}

@media (max-width: 767px) {
  .demo-box {
    background-color: $light-gray;
    padding: 0;

    .demo-main {
      > .title {
        padding: 0 0;

        .text {
          &.big {
            padding-bottom: 10px;
          }

          &.small {
            line-height: 1;
          }
        }
      }

      .top {
        .detail {
          ul {
            border-radius: 6px;
            grid-template-columns: repeat(auto-fill, 100%);
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 5%;
              border-bottom: 2px solid $primary;
              width: 90%;
            }

            li {
              background-color: $white;

              &:first-child {
                border-right: none;
              }
            }
          }

          span {
            @include rtl-sass-prop(margin-left, margin-right, 0);
          }
        }

        .download {
          margin-top: 20px;

          ul {
            background-color: $white;
            grid-template-columns: repeat(auto-fill, calc((100% - 2% * 2) / 3));

            li .clickable {
              border-style: solid;
              border-width: $border-width;
              border-color: $border-color;
              border-radius: 6px;
              background-color: $light-gray;
              height: 105px;
            }
          }
        }
      }

      .bottom {
        margin-top: 40px;

        .text {
          @include rtl-sass-value(text-align, left, right);
        }

        .boxs {
          > div {
            border-radius: 8px;
            background-color: $white;
            height: 156px;
            padding: 10px;

            .title {
              margin-bottom: 0;
              height: 50px;
            }

            img {
              width: 93px;
              height: 66px;
            }
          }
        }
      }
    }
  }
}
